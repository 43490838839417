// START OF OPTIONS TOGGLE JAVASCRIPT
// Javascript to toggle Region sort button
var regionDropdown = document.getElementById('region-dropdown-div');
var cityDropdown = document.getElementById('city-dropdown-div');

document.getElementById('region-sort').addEventListener('click', function(e) {
  e.stopPropagation();
  regionDropdown.classList.remove('hidden');
  cityDropdown.classList.add('hidden');
});


regionDropdown.addEventListener('click', function(e) {
  regionDropdown.classList.add('hidden');
  document.getElementById('region-selection').innerText = e.target.innerText;
});

// JavaScript to toggle City Sort button
document.getElementById('city-sort').addEventListener('click', function(e) {
  e.stopPropagation();
  cityDropdown.classList.remove('hidden');
  regionDropdown.classList.add('hidden');
});

cityDropdown.addEventListener('click', function(e) {
  cityDropdown.classList.add('hidden');
  document.getElementById('city-selection').innerText = e.target.innerText;
});

// Toggle off any sort options when clicking away from them
document.addEventListener('click', function(e) {
  var dropdownOptions = document.querySelectorAll('#city-dropdown-div, #region-dropdown-div');
  var dropdownArray = [...dropdownOptions];

  dropdownArray.forEach(ele => {
    if (!ele.classList.contains('hidden')) {
      ele.classList.add('hidden');
    }
  });
});
// END OF OPTIONS TOGGLE JAVASCRIPT
// Mutation observers for span elements

const currentRegion = document.getElementById('region-selection');

const mutationConfig = { attributes: false, childList: true, subtree: false }

const regionCallback = function(mutation) {

  var selected_region = currentRegion.innerText;

  // Send request to sort if region selected
  // Hide city / reset if no region or unknown region selected
  if (selected_region === '1' || selected_region === '') {
    $('.city-sort-section').hide();
  } else {
    $('.reset-section').css('display', 'inline');
    // Show city sort based on region selected if known region
    $.ajax({
      dataType: 'json',
      url: `/get_cities_by_region/${selected_region}`,
      cache: false,
      timeout: 10000,
      error: function(XMLHttpRequest, errorText, error) {
        alert(`Failed to submit consider skipping this value due to ${errorText} ${error}`);
      },
      success: function(data) {
        $('#city-sort').css("display", 'inline-block');
        document.querySelector('#city-selection').innerHTML = 'Nearest City'
        $('#city-dropdown-div').empty();

        $.each(data, function(i, j) {
          option = "<a class=\"nav-sort-selection\" data-remote='true' href=\"/cities/" + j.id + "\" value=\"" + j.id + "\">" + j.name +  "</a>";
          $(option).appendTo("#city-dropdown-div");
        })
      }
    })
  }
}
const regionObserver = new MutationObserver(regionCallback);
regionObserver.observe(currentRegion, mutationConfig);

option = "<a class=\"nav-sort-selection\" data-remote='true' href=``> j.name </a>";
